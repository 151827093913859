@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;900&display=swap');

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

html {
  scroll-behavior: smooth; /* Enable smooth scrolling */
}

body {
  font-family: 'Rubik', sans-serif;
  background: #0a0a0a; /* Dark background color */
}

.app-background {
  position: relative;
  width: 100%;
  min-height: 100vh; /* Use min-height instead of height */
  overflow: hidden; /* Ensure no overflow */
  background: url('/public/Simple.svg') no-repeat center center fixed; /* Use the SVG as background */
  background-size: cover; /* Ensure the background covers the entire area */
}

#ripple-canvas {
  position: fixed; /* Ensure canvas is fixed */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  filter: blur(25px);
  z-index: 1; /* Ensure canvas is above the background */
}

.background-wrapper {
  position: relative;
  z-index: 2; /* Ensure content is above the canvas */
}

.navbar {
  position: absolute;
  top: 1rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem; /* Space between links */
  font-family: 'Rubik', sans-serif;
  background: rgba(0, 0, 0, 0.3); /* Semi-transparent background */
  padding: 0.5rem 1rem;
  border-radius: 8px; /* Rounded corners */
}

.about-section h2 {
  font-size: 4rem; /* Increase font size */
  font-weight: 900; /* Make the text bold */
  color: #fcfcfc; /* Light text color */
  background: linear-gradient(120deg, #fcfcfc, #70C7BA, #fcfcfc); /* Gradient effect */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  margin-bottom: 2rem; /* Add some space below the heading */
}

.navbar a {
  color: #fcfcfc;
  text-decoration: none;
  font-size: 1rem; /* Reduced font size */
  font-weight: 400;
  transition: color 0.3s, transform 0.3s; /* Added transform transition */
}

.navbar a:hover {
  color: #70C7BA;
  transform: scale(1.1); /* Slightly enlarge on hover */
}

@keyframes waveGradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.intro-text {
  position: absolute;
  top: 5%; /* Stick to the top */
  left: 50%;
  transform: translate(-50%, 0); /* Adjust transform to align horizontally */
  background: linear-gradient(120deg, #fcfcfc, #70C7BA, #fcfcfc);
  background-size: 200% 200%; /* Make the gradient larger to create a wave effect */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 9rem; /* Further increased font size */
  font-weight: 900; /* Increased font weight */
  text-align: center;
  font-family: 'Rubik', sans-serif; /* Applied Rubik font */
  animation: waveGradient 5s ease infinite; /* Apply the animation */
}

.centered-button {
  position: absolute;
  top: 40%; /* Adjust this value to move the button further down */
  left: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 0.5s ease-in-out; /* Add transition for smooth fade */
}

.centered-button.hidden {
  opacity: 0; /* Hide the button smoothly */
  pointer-events: none; /* Disable pointer events when hidden */
}

.sub-text {
  position: absolute;
  top: 13%; /* Adjust as needed to position below the main text */
  left: 50%;
  transform: translate(-50%, 0); /* Adjust transform to align horizontally */
  font-size: 2rem; /* Adjust font size as needed */
  font-weight: 400; /* Adjust font weight as needed */
  text-align: center;
  font-family: 'Rubik', sans-serif; /* Applied Rubik font */
  color: #fcfcfc; /* Adjust color as needed */
}

.sub-text span {
  background: linear-gradient(60deg, #70C7BA 10%, #fcfcfc 90%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 900; /* Match the font weight of the heading */
}

.social-media-links {
  position: absolute;
  top: 20%; /* Adjust as needed to position below the sub-text */
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  gap: 2rem; /* Space between links */
  font-size: 1.5rem; /* Adjust font size as needed */
  color: #fcfcfc; /* Light text color */
}

.social-media-links a {
  color: #fcfcfc;
  text-decoration: none;
  transition: color 0.3s, transform 0.3s;
}

.social-media-links a:hover {
  color: #70C7BA;
  transform: scale(1.1); /* Slightly enlarge on hover */
}

.social-media-links i {
  font-size: 3.5rem; /* Adjust icon size as needed */
}

.content {
  padding-top: 60vh; /* Adjusted padding to move the section higher */
}

.section {
  padding: 25rem 5rem; /* Increase padding to make sections taller */
  text-align: center;
  font-family: 'Rubik', sans-serif;
  background: #0a0a0a; /* Dark background color */
}

.section:nth-child(even) {
  background: #111; /* Slightly lighter dark background for even sections */
}

.section h2 {
  font-size: 3rem;
  color: #fcfcfc; /* Light text color */
}

.card-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 2rem;
  padding: 2rem 0;
}

.card {
  background: #1a1a1a;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  width: 15%;
  min-width: 250px;
  height: 300px; /* Make the card taller */
  text-align: left;
  transition: transform 0.3s, box-shadow 0.3s;
  animation: waveGlow 5s ease infinite; /* Apply the animation */
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.card-icon {
  font-size: 2.5rem;
  color: #70C7BA;
  margin-bottom: 1rem;
}

.card-title {
  font-size: 1.25rem;
  color: #fcfcfc;
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.card-description {
  font-size: 0.875rem;
  color: #ccc;
  line-height: 1.5;
}

@keyframes waveGlow {
  0% {
    box-shadow: 0 0 15px rgba(111, 199, 186, 0.5), 0 0 30px rgba(111, 199, 186, 0.3), 0 0 45px rgba(111, 199, 186, 0.1);
  }
  50% {
    box-shadow: 0 0 30px rgba(111, 199, 186, 0.5), 0 0 60px rgba(111, 199, 186, 0.3), 0 0 90px rgba(111, 199, 186, 0.1);
  }
  100% {
    box-shadow: 0 0 15px rgba(111, 199, 186, 0.5), 0 0 30px rgba(111, 199, 186, 0.3), 0 0 45px rgba(111, 199, 186, 0.1);
  }
}

.section p {
  font-size: 1.2rem;
  color: #ccc; /* Light text color */
  max-width: 800px;
  margin: 0 auto;
}

button.gradient-button {
  border: 0.08rem solid transparent;
  position: relative;
  color: #F3F3F3;
  font-family: 'Rubik', sans-serif;
  font-size: 1.5rem;
  border-radius: 0.7rem;
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: center;
  cursor: pointer;
  background: linear-gradient(#121213, #121213), 
              linear-gradient(#121213 50%, rgba(18, 18, 19, 0.6) 80%, rgba(18, 18, 19, 0)), 
              linear-gradient(90deg, #70C7BA, #fcfcfc, #70C7BA);
  background-origin: border-box;
  background-clip: padding-box, border-box, border-box;
  background-size: 200%;
  animation: animate 2s infinite linear;
}

button.gradient-button::before {
  content: '';
  background: linear-gradient(90deg, #70C7BA, #fcfcfc, #70C7BA);
  height: 30%;
  width: 60%;
  position: absolute;
  bottom: -20%;
  z-index: -5;
  background-size: 200%;
  animation: animate 2s infinite linear;
  filter: blur(0.8rem);
}

button.gradient-button:hover, button.gradient-button:hover::before {
  animation: animate 0.5s infinite linear;
}

@keyframes animate {
  0% {background-position: 0}
  100% {background-position: 200%}
}

@media screen and (max-width: 1000px) {
  button.gradient-button {
    font-size: 1rem;
    padding: 0.5rem 1rem;
  }
}